
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.colors {
  &__block {
    margin-bottom: 30px;
  }
  &__title {
    font-weight: bold;
    margin-bottom: 16px;
  }
  .block__row {
    justify-content: flex-start;
    gap: 20px;
  }
  .block__col {
    width: calc(25% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__example {
    margin-bottom: 16px;
    display: flex;
    gap: 16px;
    pointer-events: none;
  }
  &__button {
    margin-bottom: 20px;
    border: 1px solid $border-color-base;
    border-radius: $base-border-radius;
    padding: 20px;
  }
}
